import * as React from 'react'
import cx from 'classnames'
import { useUniqueId } from '@toasttab/buffet-utils'
import {
  clampTimePoint,
  getDisabledState,
  hasCustomRenderComponent
} from './utils'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import { TimePoint } from '@toasttab/buffet-pui-date-utilities'
import {
  HelperText,
  Legend,
  LabeledHelperTextProps
} from '@toasttab/buffet-pui-text-base'
import {
  AmPmToggle,
  HoursInput,
  MinutesInput,
  TimePickerInputRenderProps
} from './subFields'
import { t, loadStrings } from '../defaultStrings'

export type TimePickerInputRenderProp =
  | true
  | ((renderProps: TimePickerInputRenderProps) => React.ReactElement)

export interface TimePickerProps
  extends Omit<LabeledHelperTextProps, 'disabled'>,
    TestIdentifiable {
  value?: TimePoint
  onChange?: (newValue: TimePoint) => void
  onBlur?: () => void
  /** Classes to apply to the wrapper around the input fields. */
  className?: string
  /** Classes to apply to the container around the label, fields, and helper text. */
  containerClassName?: string
  /** Invalidity state of the selected time. */
  invalid?: boolean
  /** A flag to disable the entire field or each sub-field individually. */
  disabled?:
    | boolean
    | Partial<{
        hours: boolean
        minutes: boolean
        isPm: boolean
      }>
  /** The default value to fall back to if any field is cleared out. */
  defaultValue?: Partial<TimePoint>
  /**
   * A custom render function for the hours input field.
   * @example
   * ```tsx
   * <TimePicker
   *   // ...other props...
   *   renderHoursInput={(props) =>
   *     <TimePicker.HoursInput
   *       {...props}
   *       placeholder='enter hours'
   *     />
   *   }
   * />
   * ```
   */
  renderHoursInput?: TimePickerInputRenderProp
  /**
   * A flag to remove the minutes input or
   * a custom render function for the minutes input field.
   * @example
   * ```tsx
   * <TimePicker
   *   // ...other props...
   *   renderMinutesInput={(props) =>
   *     <TimePicker.MinutesInput
   *       {...props}
   *       placeholder='enter minutes'
   *     />
   *   }
   * />
   * ```
   */
  renderMinutesInput?: TimePickerInputRenderProp | null | false
  /**
   * A flag to remove the am/pm toggle or
   * a custom render function for the am/pm field.
   * If disabled, the time picker will use military time.
   * @example
   * ```tsx
   * <TimePicker
   *   // ...other props...
   *   renderAmPmToggle={(props) =>
   *     <TimePicker.AmPmToggle
   *       ref={amPmToggleRef}
   *       {...props}
   *     />
   *   }
   * />
   * ```
   */
  renderAmPmToggle?: TimePickerInputRenderProp | null | false
}

export const TimePicker = ({
  value,
  testId,
  className,
  containerClassName,
  onChange,
  onBlur,
  defaultValue = {},
  disabled = false,
  renderHoursInput,
  renderMinutesInput,
  renderAmPmToggle,
  label,
  name,
  required,
  helperIconButton,
  ...helperTextProps
}: TimePickerProps) => {
  testId = useUniqueId(testId, 'time-picker-')
  loadStrings()

  const isInvalid = Boolean(helperTextProps.invalid)
  const fullDefaultValue = {
    hours: defaultValue?.hours ?? null,
    minutes: defaultValue?.minutes ?? null,
    isPm: defaultValue?.isPm ?? false
  }

  const disabledState = getDisabledState(disabled)
  const isDisabled =
    disabledState.hours && disabledState.minutes && disabledState.isPm
  const [internalValue, setInternalValue] = React.useState<TimePoint>(() => ({
    ...fullDefaultValue,
    ...value
  }))

  const hoursInput = hasCustomRenderComponent(renderHoursInput)
    ? renderHoursInput
    : (props: TimePickerInputRenderProps) => (
        <HoursInput {...props} testId={`${testId}-hours-input`} />
      )

  const minutesInput = hasCustomRenderComponent(renderMinutesInput)
    ? renderMinutesInput
    : (props: TimePickerInputRenderProps) => (
        <MinutesInput {...props} testId={`${testId}-minutes-input`} />
      )

  const amPmToggle = hasCustomRenderComponent(renderAmPmToggle)
    ? renderAmPmToggle
    : (props: TimePickerInputRenderProps) => (
        <AmPmToggle
          commitValue={props.commitValue}
          value={props.value}
          disabled={props.disabled}
          testId={`${testId}-am-pm-toggle`}
        />
      )

  const commitValueChanged = (newValue?: Partial<TimePoint>) => {
    const clampedValue = clampTimePoint(
      {
        ...internalValue,
        ...newValue
      },
      fullDefaultValue,
      Boolean(amPmToggle)
    )

    onBlur?.()
    onChange?.(clampedValue)
    setInternalValue(clampedValue)
  }

  const renderProps = {
    value: internalValue,
    invalid: isInvalid,
    required: required,
    disabled: disabledState,
    commitValue: commitValueChanged,
    changeValue: setInternalValue
  }

  return (
    <div className={containerClassName} data-testid={`${testId}-container`}>
      <fieldset>
        <Legend
          name={name}
          disabled={isDisabled}
          required={required}
          helperIconButton={helperIconButton}
          className={cx(!label && 'sr-only')}
        >
          {label || t('select-a-time')}
        </Legend>
        <div
          data-testid={testId}
          className={cx('flex items-center mt-1', className)}
        >
          {hoursInput(renderProps)}
          {minutesInput && (
            <>
              <span className='mx-1'>:</span>
              {minutesInput(renderProps)}
            </>
          )}
          {amPmToggle && amPmToggle(renderProps)}
        </div>
      </fieldset>
      <HelperText
        testId={`${testId}-helper-text`}
        disabled={isDisabled}
        {...helperTextProps}
      />
    </div>
  )
}

TimePicker.HoursInput = HoursInput
TimePicker.MinutesInput = MinutesInput
TimePicker.AmPmToggle = AmPmToggle
