import * as React from 'react'
import cx from 'classnames'
import { useUniqueId } from '@toasttab/buffet-utils'
import { useField } from 'formik'
import { RadioButton, RadioButtonProps } from '@toasttab/buffet-pui-radio-group'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'
import {
  SelectionGroup,
  SelectionGroupProps,
  inlineSelectionItemsClasses
} from '@toasttab/buffet-pui-input-base'
import { FormValuesWithName, TypedName } from '../commonTypes'

export interface RadioGroupFieldProps<
  FormValues extends FormValuesWithName = string
> extends Omit<
      SelectionGroupProps,
      'children' | 'name' | 'onChange' | 'onBlur'
    >,
    TestIdentifiable,
    TypedName<FormValues> {
  options?: {
    value: string
    subLabel?: string
    label: string
    disabled?: boolean
  }[]
  inline?: boolean
  onChange?: React.HTMLProps<HTMLInputElement>['onChange']
  onBlur?: React.HTMLProps<HTMLInputElement>['onBlur']
}

export const RadioGroupField = <FormValues extends FormValuesWithName = string>(
  props: RadioGroupFieldProps<FormValues>
) => {
  const {
    options = [],
    disabled,
    testId,
    onChange,
    onBlur,
    name,
    inline,
    itemsContainerClassName,
    ...restProps
  } = props

  const derivedTestId = useUniqueId(testId, 'Radio-group-field-')
  const derivedDisabled = options.every((option) => option.disabled) || disabled
  const [, meta] = useField({ name, type: 'radio' })
  const errorText = typeof meta.error === 'string' && meta.error
  const invalid = meta.touched && !!errorText
  return (
    <SelectionGroup
      disabled={derivedDisabled}
      multiple={true}
      {...restProps}
      itemsContainerClassName={cx(
        'my-2',
        inline && inlineSelectionItemsClasses,
        itemsContainerClassName
      )}
      testId={derivedTestId}
      invalid={invalid}
      errorText={errorText}
    >
      {options.map((op) => (
        <RadioButtonField<FormValues>
          name={name}
          label={op.label}
          subLabel={op.subLabel}
          value={op.value}
          disabled={op.disabled || disabled}
          key={op.value}
          containerClassName={cx(inline && 'mr-8')}
          onChange={onChange}
          onBlur={onBlur}
        />
      ))}
    </SelectionGroup>
  )
}

export type RadioButtonFieldProps<FormValues extends FormValuesWithName> =
  RadioButtonProps & TypedName<FormValues>

export const RadioButtonField = <
  FormValues extends FormValuesWithName = string
>(
  props: RadioButtonFieldProps<FormValues>
) => {
  const [field] = useField({ ...props, type: 'radio' })

  return (
    <RadioButton
      {...field}
      {...props}
      onChange={(event) => {
        props.onChange?.(event)
        field.onChange(event)
      }}
      onBlur={(event) => {
        props.onBlur?.(event)
        field.onBlur(event)
      }}
    />
  )
}
