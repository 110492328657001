import { AutoSuggestTextInputField } from '@toasttab/buffet-pui-forms'
import { Partner } from '@local/manage-webhooks-shared'
import { useCallback, useMemo } from 'react'

export function SelectPartnerField({ partners }: { partners: Partner[] }) {
  const availablePartners = useMemo(() => partners, [partners])
  const partnersNames = useMemo(
    () => availablePartners.map((partner: Partner) => partner.name),
    [availablePartners]
  )

  const findMatchFromAvailablePartners = useCallback(
    (partner: string) => {
      return availablePartners.find((p) => p.name === partner)
    },
    [availablePartners]
  )

  const findByNameOrGuid = useCallback(
    (partner: string, inputValue: string) => {
      const partnerMatch = findMatchFromAvailablePartners(partner)
      if (partnerMatch?.name.toLowerCase().includes(inputValue.toLowerCase()))
        return true
      if (partnerMatch?.guid.toLowerCase().includes(inputValue.toLowerCase()))
        return true
    },
    [availablePartners]
  )

  const filterPartners = useCallback(
    (partners: string[], inputValue?: string) => {
      return (
        partners.filter((partner) => {
          if (!inputValue) return true
          if (findByNameOrGuid(partner, inputValue)) return true
        }) ?? []
      )
    },
    []
  )

  return (
    <AutoSuggestTextInputField
      required
      name='subscriberName'
      testId='subscriber-name'
      label='Subscriber name'
      items={partnersNames}
      placeholder='Type to search for a partner by name or by guid'
      suffixVariant='iconButton'
      renderItem={(value: string | null) =>
        renderSinglePartnerItem(value, availablePartners)
      }
      filterItems={filterPartners}
    />
  )
}

function renderSinglePartnerItem(value: string | null, partners: Partner[]) {
  if (!value) return null

  const partner = partners.filter(
    (partner: Partner) => partner.name === value
  )[0]
  if (!partner) return null

  return (
    <>
      <div className='type-default font-normal text-default'>
        {partner.name}
      </div>
      <div className='type-caption font-normal text-secondary'>
        {partner.guid}
      </div>
    </>
  )
}
