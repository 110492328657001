import * as React from 'react'
import { FormikErrors, useField } from 'formik'

export const flattenErrorsObject = (
  errorsObject:
    | string
    | string[]
    | undefined
    | FormikErrors<any>
    | FormikErrors<any>[]
): string[] => {
  if (typeof errorsObject === 'string') {
    return [errorsObject]
  }

  if (!errorsObject) {
    return []
  }

  return Object.values(errorsObject).flatMap((error) =>
    flattenErrorsObject(error)
  )
}

export const useNestedFieldErrors = <T>(name: string): string[] => {
  const [, meta] = useField<T>(name)
  return React.useMemo(() => flattenErrorsObject(meta.error), [meta.error])
}
