import * as React from 'react'
import { useField } from 'formik'
import {
  DatePickerInput,
  DatePickerInputProps
} from '@toasttab/buffet-pui-date-picker'
import { RemoveFields } from '@toasttab/buffet-shared-types'
import { FormValuesWithName, TypedName } from '../commonTypes'

export type DatePickerInputFieldProps<FormValues extends FormValuesWithName> =
  RemoveFields<DatePickerInputProps, 'name'> & TypedName<FormValues>

export const DatePickerInputField = <
  FormValues extends FormValuesWithName = string
>(
  props: DatePickerInputFieldProps<FormValues>
) => {
  // @ts-ignore
  const [field, meta, helpers] = useField<Date | undefined>({
    name: props.name,
    value: props.value
  })

  const errorText = meta.error
  const invalid = meta.touched && !!errorText
  const actualOnChange = props.onChange || props.onValueChange // eslint-disable-line deprecation/deprecation

  return (
    <DatePickerInput
      invalid={invalid}
      errorText={errorText}
      {...field}
      {...props}
      onChange={(day, selectedDay, activeModifiers, event) => {
        helpers.setTouched(true)
        helpers.setValue(day)
        actualOnChange?.(day, selectedDay, activeModifiers, event)
      }}
    />
  )
}
