import { ApolloProvider } from '@apollo/client'
import { AppRoutes } from '@local/app-routes'
import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'
import { DomainContextProvider } from '@local/current-domain'
import { AdminPermissionContextProvider } from '@local/manage-webhooks-shared'

import { useApolloClient } from './apolloClient'

export const App = () => {
  return (
    <DomainContextProvider>
      <AdminPermissionContextProvider>
        <ApolloProvider client={useApolloClient()}>
          <SnackBarProvider>
            <div id='banquetPortalsContainer' />
            <AppRoutes />
          </SnackBarProvider>
        </ApolloProvider>
      </AdminPermissionContextProvider>
    </DomainContextProvider>
  )
}
