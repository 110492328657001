import React from 'react'
import cx from 'classnames'
import { useUniqueId } from '@toasttab/buffet-utils'
import { TextInput, TextInputProps } from '@toasttab/buffet-pui-text-input'
import { HelperText, Label, LabelProps } from '@toasttab/buffet-pui-text-base'
import {
  PortalManagement,
  useFloatingComponentZIndexClass,
  Overlay,
  useMergeRefs,
  useDropdownCommon
} from '@toasttab/buffet-pui-floating-ui-base'

export function defaultFilterItems<T>(
  items: T[],
  itemToString?: any,
  searchTerm?: string
): T[] {
  return items.filter(
    (item) =>
      !searchTerm ||
      itemToString(item).toLowerCase().startsWith(searchTerm.toLowerCase())
  )
}

export interface AutoSuggestTextInputProps<T>
  extends Omit<TextInputProps, 'onChange' | 'defaultValue' | 'value'> {
  /**
   * value is required
   */
  value: string | undefined
  /**
   * The onChange callback receives two arguments instead of the event. The first
   * argument is the value of the text input. The second argument is the item if
   * the current value is a match for one of the items you are using.
   */
  onChange: (value: string, item?: T) => void
  /**
   * The items to use for the dropdown menu
   */
  items: Array<T>
  /**
   * Use this to create a custom filter function for your items
   */
  filterItems?: (items: Array<T>, searchTerm?: string) => Array<T>
  /**
   * a function that determines how each item (option) will be rendered to string
   */
  itemToString?: (item: T | null) => string
  /**
   *  a function that determines how each item (option) will be rendered
   */
  renderItem?: (item: T | null) => React.ReactNode
  /**
   * Set this to true if the input should open as soon as it is focussed
   */
  openOnFocus?: boolean
  /**
   * An optional function to call when the dropdown opens or closes
   */
  onOpenChange?: (isOpen: boolean) => void
  /**
   * An optional function that allows for additional functionality after a key press.
   */
  onKeyUp?: (event: React.KeyboardEvent<Element>) => void
}

export const AutoSuggestTextInput = React.forwardRef(
  function AutoSuggestTextInput<T extends { id?: string }>(
    {
      items: unfiltered,
      filterItems = defaultFilterItems,
      name,
      id,
      label,
      containerClassName,
      disabled,
      testId,
      invalid,
      helperText,
      helperIconButton,
      errorText,
      preserveHelpSpace,
      autoComplete = 'off',
      openOnFocus,
      onFocus,
      onBlur,
      onChange,
      onOpenChange,
      onKeyUp,
      itemToString = (item) => `${item}`,
      renderItem = itemToString,
      required,
      value,
      ...restProps
    }: AutoSuggestTextInputProps<T>,
    ref?: React.ForwardedRef<HTMLInputElement>
  ) {
    testId = useUniqueId(testId, 'text-input-')
    const uniqueId = useUniqueId(id, 'auto-suggest-text-input-')

    const zIndexClass = useFloatingComponentZIndexClass() // elevation: z-40 for floating components (boosted to z-50 when in modals etc)

    const items = filterItems ? filterItems(unfiltered, value) : unfiltered

    const [selectedItem, setSelectedItem] = React.useState<T | null>(null)

    const {
      refs,
      floatingStyles,
      context,
      getReferenceProps,
      getFloatingProps,
      getItemProps,
      getLabelProps,
      isOpen,
      setIsOpen,
      activeIndex,
      setActiveIndex,
      listRef
    } = useDropdownCommon<React.HTMLProps<Element>, LabelProps>({
      testId,
      id,
      hasLabel: !!label,
      ariaLabel: restProps['aria-label'],
      disabled,
      onOpenChange,
      matchReferenceWidth: true,
      contentRole: 'listbox',
      idBase: 'autosuggest',
      enableSearch: true,
      disableTypeahead: true,
      customOffset: 4
    })

    const selectItem = (item: T) => {
      onChange(itemToString(item), item)
      setSelectedItem(item)
    }

    const inputRef = useMergeRefs([ref, refs.setReference])

    const handleOnFocus: React.FocusEventHandler<HTMLInputElement> = (
      event
    ) => {
      onFocus?.(event)
      if (openOnFocus) {
        if (
          items.length > 1 ||
          (items.length === 1 && itemToString(items[0]) !== value)
        ) {
          setIsOpen(true)
        }
      }
    }

    const handleOnBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
      // A weird edge case that we don't want to bubble up:
      // When the user taps on an item in the list:
      // 1. the input will blur,
      // 2. the click event will register in the listitem;
      // 3. the input will refocus
      if (isOpen && event.relatedTarget?.getAttribute('role') === 'listbox') {
        return
      }
      onBlur?.(event)
    }

    function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
      const value = event.target.value
      onChange(value)

      setIsOpen(true)
    }

    React.useEffect(() => {
      if (!!selectedItem && isOpen) {
        setSelectedItem(null)
        setActiveIndex(null)
        setIsOpen(false)
      }
    }, [isOpen, selectedItem, setActiveIndex, setIsOpen])

    const showDropdownMenu = isOpen && !!items.length

    return (
      <div
        data-testid={testId}
        className={cx('text-default relative w-full', containerClassName)}
      >
        <div>
          {label && (
            <Label
              data-testid={`${testId}-label`}
              {...getLabelProps({
                disabled,
                name: uniqueId,
                required
              })}
              helperIconButton={helperIconButton}
            >
              {label}
            </Label>
          )}
          <div ref={refs.setPositionReference}>
            <TextInput
              name={name}
              id={uniqueId}
              testId={`${testId}-input`}
              invalid={invalid}
              disabled={disabled}
              required={required}
              // This component uses its own HelperText so that the floating element is anchored to the text input
              errorText={undefined}
              helperText={undefined}
              preserveHelpSpace={undefined}
              {...restProps}
              {...getReferenceProps({
                ref: inputRef,
                onChange: handleOnChange,
                value,
                autoComplete,
                'aria-autocomplete': 'list',
                onClick: () => {
                  setIsOpen(true)
                },
                onKeyDown: (event) => {
                  if (
                    event.key === 'Enter' &&
                    activeIndex != null &&
                    items[activeIndex]
                  ) {
                    const item = items[activeIndex]
                    selectItem(item)
                  }
                },
                onKeyUp: (event) => {
                  onKeyUp?.(event)
                },
                onBlur: handleOnBlur,
                onFocus: handleOnFocus
              })}
            />
          </div>
          <HelperText
            testId={`${testId}-helper-text`}
            disabled={disabled}
            invalid={invalid}
            errorText={errorText}
            helperText={helperText}
            preserveHelpSpace={preserveHelpSpace}
          />
        </div>

        {showDropdownMenu && (
          <PortalManagement context={context} disableInitialFocus>
            <Overlay
              lockScroll
              className={zIndexClass}
              testId={`${testId}-overlay`}
            >
              <div
                {...getFloatingProps({
                  ref: refs.setFloating,
                  style: floatingStyles,
                  className: cx('outline-none')
                })}
              >
                <ul
                  {...{
                    className: cx(
                      'overflow-auto focus:outline-none border-none',
                      {
                        'py-2 dropdown-base': !!items.length
                      }
                    ),
                    style: {
                      // 16px matches the "shift: { padding: 8 }" middleware in floating-ui
                      maxHeight: 'min(100vh - 16px, 16rem)',
                      minWidth: '7.5rem'
                    }
                  }}
                >
                  {!!items.length &&
                    items.map((item: T, index: number) => (
                      <li
                        className={cx(
                          'type-default cursor-pointer px-3 py-3 font-normal md:py-2.5',
                          {
                            'bg-darken-4': activeIndex === index
                          }
                        )}
                        key={`item-${name}-${item?.id || testId}-${index}`}
                        {...getItemProps({
                          ref(node) {
                            listRef.current[index] = node
                          },
                          onClick() {
                            selectItem(item)
                          },
                          role: 'option'
                        })}
                      >
                        {renderItem(item)}
                      </li>
                    ))}
                </ul>
              </div>
            </Overlay>
          </PortalManagement>
        )}
      </div>
    )
  }
) as React.NamedExoticComponent &
  (<T>(
    props: AutoSuggestTextInputProps<T> & React.RefAttributes<HTMLInputElement>
  ) => React.ReactElement | null)

AutoSuggestTextInput.displayName = 'AutoSuggestTextInput'
