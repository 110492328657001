import * as React from 'react'
import {
  Masks,
  selectMask,
  format,
  isValid
} from '@toasttab/buffet-pui-date-utilities'
import { Calendar, SingleCalendarProps } from '@toasttab/buffet-pui-calendar'
import {
  DatePickerContainer,
  DatePickerContainerInputProps,
  DayPickerContainerBaseProps
} from '../DatePickerContainer'
import { isMatch, Matcher } from 'react-day-picker'
import { Locale, getLocale } from '@toasttab/buffet-pui-locale-utilities'
import {
  DatePickerContextProvider,
  OnSelectEventType
} from '../useDatePickerContext'
import { useUniqueId } from '@toasttab/buffet-utils'
import { useCalendarConstraints } from '../useCalendarConstraints/useCalendarConstraints'
import { t, loadStrings } from '../defaultStrings'

export const isDateDisabled = (
  date: Date,
  disabledDates: Matcher[]
): boolean => {
  return isMatch(date, disabledDates)
}

export interface DatePickerInputProps
  extends Omit<DayPickerContainerBaseProps<Date>, 'formatValue' | 'disabled'>,
    Omit<DatePickerContainerInputProps<Date>, 'locale'>,
    Omit<
      SingleCalendarProps,
      | 'disabled'
      | 'mode'
      | 'onSelect'
      | 'locale'
      | 'numberOfMonths'
      | 'onChange'
    > {
  value?: Date
  required?: boolean
  formatValue?: string | ((value?: Date) => string | undefined)
  disabled?: boolean
  locale?: Locale
  showDateStepperButtons?: boolean
  // We redefine our own type for onChange so that we can pass `undefined` as a value for formik
  // (see `onValueChangeHandler` in DatePickerContainer)
  onChange?: OnSelectEventType<Date>
}

export const DatePickerInput = ({
  testId = `DatePickerInput`,
  containerClassName,
  disabled,
  invalid,
  required,
  errorText,
  helperText,
  helperIconButton,
  name,
  id,
  value,
  label,
  'aria-label': ariaLabel,
  preserveHelpSpace,
  placeholder,
  size = 'auto',
  placement = 'bottom-start',
  inlineBlock,
  locale: localeOverride,
  onBlur,
  onValueChange, // eslint-disable-line
  onChange,
  mask = Masks.short,
  showDateStepperButtons,
  onOpenChange,
  ...calendarProps
}: DatePickerInputProps) => {
  const [isInitialFocus, setIsInitialFocus] = React.useState<boolean>(false)
  const { minDate, maxDate } = useCalendarConstraints(calendarProps)

  loadStrings()
  const translatedPlaceholder = placeholder ?? t('select-dates')

  const uniqueId = useUniqueId(id, 'date-picker-input-')

  const locale = localeOverride || getLocale()

  const actualOnChange = onChange || onValueChange

  const containerProps = {
    testId,
    containerClassName,
    placement,
    disabled,
    invalid,
    required,
    inlineBlock,
    placeholder: translatedPlaceholder,
    showDateStepperButtons,
    size,
    name,
    id: uniqueId,
    value,
    label,
    ariaLabel,
    errorText,
    helperText,
    helperIconButton,
    preserveHelpSpace,
    onBlur,
    locale,
    mask,
    minDate,
    maxDate,
    onOpenChange
  }

  const onKeydownHandler = (event: React.KeyboardEvent) => {
    const eventsWatched = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight']

    if (eventsWatched.includes(event.key)) {
      setIsInitialFocus(true)
    } else {
      setIsInitialFocus(false)
    }
  }

  const valueFormatter = (date?: Date): string | null => {
    const parseFormat =
      selectMask(mask, locale)?.['parseFormat']['input'] || 'yyyyMMdd'
    if (!date || !isValid(date)) return null
    return format(date, parseFormat)
  }

  return (
    <DatePickerContextProvider
      locale={locale}
      value={value}
      onSelect={actualOnChange}
    >
      <DatePickerContainer<Date>
        formatValue={valueFormatter}
        allowInput
        {...containerProps}
        onKeyDown={onKeydownHandler}
        renderCalendar={(pickedDate, closeDatePickerInput, { onSelect }) => {
          return (
            <Calendar
              {...calendarProps}
              initialFocus={isInitialFocus}
              testId={`${testId}-calendar`}
              mode='single'
              selected={!invalid ? pickedDate : undefined}
              defaultMonth={pickedDate || calendarProps.defaultMonth}
              locale={locale}
              onChange={(day, selectedDay, modifiers, e) => {
                if (day) {
                  closeDatePickerInput()
                }
                // containerProps.onChange?.(day, selectedDay, modifiers, e)
                onSelect(day, selectedDay, modifiers, e)
              }}
            />
          )
        }}
      />
    </DatePickerContextProvider>
  )
}
