import { ApolloClient } from '@apollo/client'
import { getIsDepotDomain } from '@local/current-domain'
import { cache } from '@local/manage-webhooks-shared'
import { getCurrentEnvironment } from '@toasttab/current-environment'
import { useBanquetProps } from 'banquet-runtime-modules'
import { getActiveEnvironmentLabelForRouting } from '../../../packages/environment-switcher-utils'

enum HOST {
  TOASTWEB = 'TOASTWEB',
  DEPOT = 'DEPOT'
}

type ClientParams = {
  name: string
  uri: string
}

const apolloClientParams: { [host in HOST]: ClientParams } = {
  [HOST.TOASTWEB]: {
    name: 'manage-webhooks-spa-toastweb',
    uri: '/api/service/webhooks-bff/v1/graphql'
  },
  [HOST.DEPOT]: {
    name: `manage-webhooks-spa-depot`,
    uri: `/api/service/depot-bff/v1/graphql`
  }
}

const secondaryBaseUrl = `https://developer.sandbox.eng.toasttab.com`
const secondaryDepotUrl = secondaryBaseUrl.concat(
  apolloClientParams[HOST.DEPOT].uri
)

const toastwebApolloClient = new ApolloClient({
  name: apolloClientParams[HOST.TOASTWEB].name,
  cache: cache,
  uri: apolloClientParams[HOST.TOASTWEB].uri
})

const primaryDepotApolloClient = new ApolloClient({
  name: apolloClientParams[HOST.DEPOT].name,
  cache: cache,
  uri: apolloClientParams[HOST.DEPOT].uri
})
const secondaryDepotApolloClient = new ApolloClient({
  name: apolloClientParams[HOST.DEPOT].name,
  cache: cache,
  uri: secondaryDepotUrl,
  credentials: 'include'
})

export function useApolloClient() {
  const { mode } = useBanquetProps()
  const env = getCurrentEnvironment()
  const activeEnv = getActiveEnvironmentLabelForRouting(mode ?? env)

  if (!getIsDepotDomain()) {
    return toastwebApolloClient
  }

  return activeEnv === 'secondary'
    ? secondaryDepotApolloClient
    : primaryDepotApolloClient
}
