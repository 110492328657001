import { useCallback, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { FormikProvider, useFormik, Form } from 'formik'
import * as Yup from 'yup'
import { Button, ButtonGroup } from '@toasttab/buffet-pui-buttons'
import { TextInputField } from '@toasttab/buffet-pui-forms'
import { PageBody, Panel } from '@toasttab/buffet-pui-config-templates'
import { PageWrapper } from '@local/app-routes/PageWrapper/PageWrapper'
import {
  useEventByKeys,
  useReplayRetrievedEvent
} from '@local/manage-webhooks-shared'
import {
  EventFieldsContent,
  JSONContent,
  LabelledContent
} from '@local/manage-webhooks-shared/event-fields-content/EventFieldsContent'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import { WarningOutlineIcon } from '@toasttab/buffet-pui-icons'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { checkMalformedEvent } from '@local/manage-webhooks-shared/event-utils'

export interface EventKeys {
  partitionKey: string
  sortKey: string
}

export const SearchEventsPage = () => {
  const { partition, sort } = useParams()
  const navigate = useNavigate()

  const initialValues = { partitionKey: partition ?? '', sortKey: sort ?? '' }

  const [showResult, setShowResult] = useState(true)
  const { getEventByKeys, eventByKeys, eventByKeysError, eventByKeysLoading } =
    useEventByKeys(initialValues.partitionKey, initialValues.sortKey)
  const { executeReplayEvent, replayLoading } = useReplayRetrievedEvent()

  const form = useFormik<EventKeys>({
    initialValues,
    onSubmit: (value) => {
      setShowResult(true)
      getEventByKeys({
        variables: { partitionKey: value.partitionKey, sortKey: value.sortKey }
      })
      navigate(`/event-search/${value.partitionKey}/${value.sortKey}`)
    },
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: Yup.object().shape({
      partitionKey: Yup.string().required('A partition key is required.'),
      sortKey: Yup.string().required('A sort key is required.')
    })
  })
  const EventResultPanel = useCallback(() => {
    if (eventByKeysLoading) return <MerryGoRound className='m-auto' size='lg' />

    if (eventByKeysError)
      return (
        <Panel className='mt-8'>
          <EmptyState
            className='m-8'
            children={
              'No webhook events found. Please retry using different keys.'
            }
            icon={<WarningOutlineIcon accessibility='decorative' />}
          />
        </Panel>
      )

    if (eventByKeys) {
      const { messageBody, jsonParsingError } = checkMalformedEvent(
        eventByKeys.messageBody ?? ''
      )
      const isSaveBodyDisabled =
        jsonParsingError &&
        messageBody.match(
          /saving the webhook details is disabled for this subscription/i
        )
      return (
        <Panel className='mt-8'>
          {!jsonParsingError && (
            <ButtonGroup className='justify-end'>
              <Button
                onClick={() => executeReplayEvent(eventByKeys)}
                variant='secondary'
                testId={'replay-button'}
                disabled={replayLoading}
              >
                Replay
              </Button>
            </ButtonGroup>
          )}
          <div className='grid grid-flow-row grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2'>
            <EventFieldsContent event={eventByKeys} />
            <LabelledContent label='Message body' className='col-span-full '>
              {!jsonParsingError && (
                <JSONContent>
                  <pre>{messageBody}</pre>
                </JSONContent>
              )}
              {jsonParsingError && !isSaveBodyDisabled && (
                <Alert
                  variant='error'
                  className='w-full'
                  testId={'malformed-json-alert'}
                >
                  The message body contained malformed JSON. It may not be
                  formatted correctly.
                </Alert>
              )}
              {jsonParsingError && <JSONContent>{messageBody}</JSONContent>}
            </LabelledContent>
          </div>
        </Panel>
      )
    }

    return null
  }, [
    eventByKeys,
    eventByKeysError,
    eventByKeysLoading,
    executeReplayEvent,
    replayLoading
  ])

  return (
    <PageWrapper>
      <PageBody className='tpc-manage-webhooks-spa'>
        <h1 className='type-large my-8'>
          Search for a webhook event by entering the keys found in the Splunk
          logs provided by router service
        </h1>
        <FormikProvider value={form}>
          <Form className='gap-4 flex flex-col'>
            <TextInputField
              name='partitionKey'
              label='Partition key'
              testId='partition-key-field'
              required
              helperText='Provide the value of webhook_event_search_partition_key for the event found in the Splunk logs'
            />
            <TextInputField
              name='sortKey'
              label='Sort key'
              testId='sort-key-field'
              required
              helperText='Provide the value of webhook_event_search_sort_key for the event found in the Splunk logs'
            />
            <ButtonGroup>
              <Button
                className='w-fit'
                disabled={
                  !form.isValid ||
                  (!form.values.partitionKey && !form.values.sortKey)
                }
                testId='submit-search-event'
                type='submit'
              >
                Search
              </Button>
              <Button
                className='w-fit ml-4'
                variant='secondary'
                disabled={!form.values.partitionKey && !form.values.sortKey}
                testId='clear-search-event'
                onClick={() => {
                  setShowResult(false)
                  form.setValues({ partitionKey: '', sortKey: '' }, false)
                }}
              >
                Clear
              </Button>
            </ButtonGroup>
          </Form>
        </FormikProvider>

        {showResult && <EventResultPanel />}
      </PageBody>
    </PageWrapper>
  )
}
