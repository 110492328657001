/* Locale date strings for en-GB locale */
import { OptionalFormats, OptionalDateMaskFormat } from './default'

export const enGB: OptionalFormats = {
  date_short_without_year: 'dd/MM',
  date_shorter: 'dd/MM/yy',
  date_short: 'P',
  date_medium_without_year: 'd MMM',
  date_long: 'd MMMM yyyy',
  date_long_without_year: 'd MMMM',
  time_shorter: 'h aaa',
  time_short: 'h:mm aaa',
  dateTime_shorter: 'dd/MM/yy, h aaa',
  dateTime_short: 'P, h:mm aaa',
  dateTime_short_24: 'P, HH:mm',
  dateTime_medium: 'd MMM yyyy, h:mm aaa'
}

export const enGBMasks: OptionalDateMaskFormat = {
  mask_short: {
    placeholder: ['DD', 'MM', 'YYYY'],
    delimiter: '/',
    mask: ['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y'],
    pattern: ['##', '##', '####'],
    parseFormat: {
      input: 'ddMMyyyy',
      output: 'dd/MM/yyyy'
    }
  }
}
