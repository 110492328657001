import * as React from 'react'
import { TextToggleSwitch } from '@toasttab/buffet-pui-toggle-switches'
import { TimePickerToggleInputProps } from './types'
import { loadStrings, t } from '../../defaultStrings'

export const AmPmToggle = React.forwardRef<
  HTMLInputElement,
  TimePickerToggleInputProps
>(
  (
    { testId, value, commitValue, defaultValue, disabled, ...toggleInputProps },
    ref
  ) => {
    loadStrings()
    return (
      <TextToggleSwitch
        ref={ref}
        testId={testId}
        disabled={disabled.isPm}
        options={[t('time.am'), t('time.pm')]}
        uppercase
        containerClassName='flex-grow ml-2'
        className='w-24'
        isActive={value?.isPm}
        defaultChecked={defaultValue?.isPm}
        onChange={() =>
          commitValue({
            isPm: !value?.isPm
          })
        }
        label={t('time-of-day')}
        hideLabel
        {...toggleInputProps}
      />
    )
  }
)
