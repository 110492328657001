import * as React from 'react'
import { TimePicker, TimePickerProps } from '@toasttab/buffet-pui-time-picker'
import { TimePoint } from '@toasttab/buffet-pui-date-utilities'
import { RemoveFields, TestIdentifiable } from '@toasttab/buffet-shared-types'
import { useField } from 'formik'
import { useNestedFieldErrors } from '../useNestedFieldErrors'
import { FormValuesWithName, TypedName } from '../commonTypes'

export type TimePickerFieldProps<
  FormValues extends FormValuesWithName = string
> = TestIdentifiable &
  RemoveFields<TimePickerProps, 'name'> &
  TypedName<FormValues>

export const TimePickerField = <
  FormValues extends FormValuesWithName = string
>({
  name,
  testId,
  onChange,
  onBlur,
  ...timePickerProps
}: TimePickerFieldProps<FormValues>) => {
  const [field, meta, helpers] = useField<TimePoint>(name)
  const [errorText] = useNestedFieldErrors(name)
  const invalid = meta.touched && errorText != null
  const internalOnChange = (time: TimePoint) => {
    helpers.setValue(time)
    onChange?.(time)
  }
  const internalOnBlur = () => {
    helpers.setTouched(true)
    onBlur?.()
  }

  return (
    <TimePicker
      {...timePickerProps}
      testId={testId}
      invalid={invalid}
      value={field.value}
      onChange={internalOnChange}
      onBlur={internalOnBlur}
      errorText={errorText}
    />
  )
}
