import { Cell, Row } from '@toasttab/buffet-pui-table'
import * as ReactTable from 'react-table'
export const getTableRows = (
  rows: ReactTable.Row<object>[],
  prepareRow: any,
  columns: ReactTable.Column<object>[],
  testId: string
) => {
  return rows.map((row) => {
    prepareRow(row)
    const { key, ...rowProps } = row.getRowProps()

    return (
      <Row key={key} {...rowProps} testId={testId}>
        {row.cells.map((cell, idx) => {
          return (
            <Cell
              className='truncate align-middle'
              testId={`value-${columns[idx].accessor}`}
              {...cell.getCellProps()}
            >
              {cell.render('Cell') as any}
            </Cell>
          )
        })}
      </Row>
    )
  })
}
