import * as React from 'react'
import cx from 'classnames'
import { t, loadStrings } from '../defaultStrings'

export interface RequiredTextProps {
  testId?: string
  className?: string
}

export const RequiredText = ({
  testId = 'required-message',
  className
}: RequiredTextProps) => {
  loadStrings()
  return (
    <div
      className={cx('type-caption flex items-start text-secondary', className)}
      data-testid={testId}
    >
      {t('indicates-a-required-field')}
    </div>
  )
}
