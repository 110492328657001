import * as React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import { t, loadStrings } from '../defaultStrings'

export interface CancelButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export const CancelButton = ({
  children,
  className,
  ...props
}: CancelButtonProps) => {
  loadStrings()
  if (!children) {
    children = t('cancel')
  }
  return (
    <Button className={className} type='reset' variant='secondary' {...props}>
      {children}
    </Button>
  )
}
