import { Path, RemoveFields } from '@toasttab/buffet-shared-types'
import { useField } from 'formik'
import React from 'react'
import {
  AutoSuggestTextInput,
  AutoSuggestTextInputProps
} from '@toasttab/buffet-pui-lookup'
import { FormValuesWithName, TypedName } from '../commonTypes'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { CloseIcon } from '@toasttab/buffet-pui-icons'
import { t, loadStrings } from '../defaultStrings'

export type AutoSuggestTextInputFieldProps<
  FormValues extends FormValuesWithName,
  Field extends Path<FormValues> = Path<FormValues>
> = RemoveFields<
  AutoSuggestTextInputProps<string>,
  'name' | 'value' | 'onChange'
> &
  TypedName<Field> & {
    onChange?: (value: string) => void
  }

export const AutoSuggestTextInputField = <
  FormValues extends FormValuesWithName,
  Field extends Path<FormValues> = Path<FormValues>
>(
  props: AutoSuggestTextInputFieldProps<FormValues, Field>
) => {
  const { renderItem, onChange, items, ...rest } = props
  loadStrings()

  const [fields, meta, helpers] = useField({
    ...rest,
    size: undefined,
    prefix: undefined,
    onChange: undefined
  })

  const handleOnChange = (value: string) => {
    onChange?.(value)
    helpers.setTouched(true)
    helpers.setValue(value)
  }

  const errorText = meta.error
  const invalid = meta.touched && !!errorText

  return (
    <AutoSuggestTextInput
      {...rest}
      {...fields}
      items={items}
      invalid={invalid}
      errorText={errorText}
      renderItem={renderItem}
      onChange={handleOnChange}
      suffix={
        <IconButton
          data-testid='reset-selection-button'
          icon={<CloseIcon aria-label={t('close')} />}
          size='sm'
          onClick={() => {
            helpers.setValue('')
          }}
        />
      }
    />
  )
}
