import { NumberInput } from '@toasttab/buffet-pui-text-input'
import * as React from 'react'
import { formatTimeValue, isInteger } from '../utils'
import { TimePickerNumberInputProps } from './types'
import { loadStrings, t } from '../../defaultStrings'

export const MinutesInput = React.forwardRef<
  HTMLInputElement,
  TimePickerNumberInputProps
>(
  (
    {
      disabled,
      invalid,
      required,
      value,
      defaultValue,
      commitValue,
      changeValue,
      ...numberInputProps
    },
    ref
  ) => {
    loadStrings()
    return (
      <NumberInput
        ref={ref}
        disabled={disabled.minutes}
        allowLeadingZeros
        containerClassName='flex-grow w-20'
        invalid={invalid}
        format='##'
        inputMode='numeric'
        value={formatTimeValue(value?.minutes)}
        defaultValue={formatTimeValue(defaultValue?.minutes)}
        onBlur={() => commitValue()}
        onValueChange={({ floatValue }) =>
          changeValue((currentValue) => ({
            ...currentValue,
            minutes: isInteger(floatValue) ? floatValue : null
          }))
        }
        required={required}
        aria-required={required}
        aria-label={t('minute')}
        {...numberInputProps}
      />
    )
  }
)
