import * as React from 'react'
import cx from 'classnames'
import { IconWrapper, IconProps, sizes } from '../../IconWrapper'
export const EditIcon = React.forwardRef(function EditIcon(
  props: IconProps,
  ref?: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconWrapper ref={ref} {...props}>
      <>
        {
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            className={cx(
              'inline-block align-middle',
              sizes[props.size || 'sm'].className
            )}
          >
            <path
              d='M13.916 6.789l3.363 3.344M5.47 15.267l9.777-9.8a1.611 1.611 0 012.268 0l1.017 1.01a1.59 1.59 0 010 2.256l-9.777 9.8L5.47 19C5.235 19 5 18.844 5 18.533l.47-3.266z'
              stroke='currentColor'
              strokeWidth={sizes[props.size || 'sm'].strokeWidth}
              strokeMiterlimit={10}
            />
          </svg>
        }
      </>
    </IconWrapper>
  )
})
